import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import "./header.css";

const Header = ({ children }) => {
  return (
    <header>
      <div className="heading">
        <h1>
          <Link to="/">Emma Williams</Link>
        </h1>
        <nav>
          <Link to="/about">About</Link>
          <Link to="/artwork">Artwork</Link>
          <Link to="/contact">Contact</Link>
        </nav>
      </div>
      <div className="intro">{children}</div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
